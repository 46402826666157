import * as React from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Color, Vector3, BackSide, AdditiveBlending } from "three";
import {
  haloFragmentShader,
  haloVertexShader,
} from "../../../assets/shaders/Halo";

interface WormholeProps {
  position: Vector3;
  size: number;
}

const Wormhole: React.FC<WormholeProps> = ({ position, size }) => {
  const wormholeRef = React.useRef<THREE.Mesh>(null!);
  const haloMeshRef = React.useRef<THREE.Mesh>(null!);
  const haloShaderRef = React.useRef<THREE.ShaderMaterial>(null!);
  const { camera } = useThree();

  const data = {
    uniforms: {
      c: { value: 0.725 },
      p: { value: 8.0 },
      glowColor: { value: new Color(0xfffff1) },
      viewVector: {
        value: camera.position.clone().sub(position),
      },
    },
    vertexShader: haloVertexShader,
    fragmentShader: haloFragmentShader,
    side: BackSide,
    blending: AdditiveBlending,
    transparent: true,
    ref: haloShaderRef,
  };

  useFrame(() => {
    haloMeshRef.current.rotation.z -=
      0.005 * Math.random() * (Math.random() < 0.5 ? 1 : -1);
    haloMeshRef.current.rotation.x -=
      0.005 * Math.random() * (Math.random() < 0.5 ? 1 : -1);
    haloMeshRef.current.rotation.y -=
      0.005 * Math.random() * (Math.random() < 0.5 ? 1 : -1);
    wormholeRef.current.rotation.z -= 0.005;
    wormholeRef.current.rotation.x -= 0.005;
    wormholeRef.current.rotation.y -= 0.005;

    haloShaderRef.current.uniformsNeedUpdate = true;
    haloShaderRef.current.uniforms.viewVector.value = haloMeshRef.current
      .clone()
      .position.sub(camera.position)
      .negate();
  });

  return (
    <>
      <mesh position={position} ref={wormholeRef} scale={size}>
        <sphereGeometry args={[1.0, 64, 64]} />
        <meshBasicMaterial color={"black"} />
      </mesh>
      <mesh position={position} ref={haloMeshRef} scale={size * 1.15}>
        <sphereGeometry args={[1.0, 64, 64]} />
        <shaderMaterial {...data} />
      </mesh>
    </>
  );
};

export default Wormhole;
