import * as React from "react";
import { Button } from "@mui/material";
import Settings from "./Settings";

export interface GameModalProps {
  children?: React.ReactNode;
  switchCallback: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  buttonCallback: () => void;
  buttonText?: string;
  topStyle?: string;
}

const GameModal: React.FC<GameModalProps> = ({
  children,
  switchCallback,
  checked,
  buttonCallback,
  buttonText,
  topStyle,
}) => {
  return (
    <div className="GameModal" style={{ top: topStyle }}>
      {children}
      <Button
        variant="contained"
        onClick={() => buttonCallback()}
        style={{
          borderRadius: 15,
          backgroundColor: "#282c34",
          fontSize: "18px",
          color: "white",
          margin: "5%",
        }}
        className="gameModalButton"
      >
        {buttonText}
      </Button>
      <Settings switchCallback={switchCallback} checked={checked} />
    </div>
  );
};

export default GameModal;
