import {
  Mesh,
  SphereGeometry,
  MeshStandardMaterial,
  Vector3,
  TextureLoader,
} from "three";
import { WorldDimensions } from "../Game";
import { randomisePosition, createSphere } from "../Utility/ShapeUtils";

export const createAsteroids = (
  numberOfObstacles: number
): Mesh<SphereGeometry, MeshStandardMaterial>[] => {
  const obstacles: Mesh<SphereGeometry, MeshStandardMaterial>[] = [];

  const textureLoader = new TextureLoader();

  const colourMap = textureLoader.load("AsteroidTextures/Asteroid_Colour.jpg");
  const normalMap = textureLoader.load("AsteroidTextures/Asteroid_Normal.jpg");
  const occlusionMap = textureLoader.load("AsteroidTextures/Asteroid_Occlusion.jpg");
  const displacementMap = textureLoader.load("AsteroidTextures/Asteroid_Displacement.png");

  for (let i = 0; i < numberOfObstacles; i++) {
    const location = new Vector3(0, 0, 0);
    randomisePosition(location);
    const obstacle = createSphere(
      Math.max(((WorldDimensions.cellSize * 2) / 3) * Math.random(), 0.6),
      { colourMap, normalMap, occlusionMap, displacementMap },
      undefined,
      location,
      Math.random() * 360
    );
    obstacles.push(obstacle);
  }
  return obstacles;
};
