import { Mesh, Scene, MeshStandardMaterial, Texture, BoxGeometry } from "three";
import { CubeDirection, createCube, moveShapeForward, RotateShape } from "../Utility/ShapeUtils";
import { WorldDimensions } from "../Game";

interface Snake {
  head: Mesh<BoxGeometry, MeshStandardMaterial>,
  direction: CubeDirection,
  Update(): void
  UpdateDirection(newDirection: CubeDirection): void
}

const SnakeColour = "darkviolet";

class Snake implements Snake {

  direction: CubeDirection;
  newDirection: CubeDirection;
  head: Mesh<BoxGeometry, MeshStandardMaterial>;
  body: Mesh<BoxGeometry, MeshStandardMaterial>[];
  pause: boolean;
  texture?: Texture;
  isJumping: boolean; 
  atPeak: boolean;
  
  constructor(texture?: Texture) {
    this.direction = CubeDirection.forward;
    this.head = createCube(undefined, SnakeColour);
    this.body = [createCube(undefined, SnakeColour)];
    this.pause = false;
    this.newDirection = CubeDirection.forward;
    this.texture = texture;
    this.isJumping = false;
    this.atPeak = false;
  }

  UpdateHeight() {
    const currentHead = this.head.clone();
    const jumpHeight = WorldDimensions.cellSize * 2;
    if(!this.atPeak) {
      if (Math.abs(this.head.position.y - jumpHeight) < 0.01){
        this.head.position.y = jumpHeight;
        this.atPeak = true;
      } else if (this.head.position.y < jumpHeight){
        currentHead.position.y = jumpHeight;
        this.head.position.lerp(currentHead.position, 0.3);
      } 
    } else {
      if (Math.abs(this.head.position.y - 0) < 0.01) {
        this.head.position.y = 0;
        this.isJumping = false;
        this.atPeak = false;
      } else if (this.head.position.y >= 0) {
        currentHead.position.y = 0;
        this.head.position.lerp(currentHead.position, 0.2);
      }
    }
    
  }

  Update() {
    if (!this.pause) {

      // see where next head would be
      const nextHead = this.head.clone();
      const prevHead = this.head.clone();
      moveShapeForward(nextHead);

      // find the nearest cell for next head
      const nextRoundedX = Math.round(nextHead.position.x / WorldDimensions.cellSize) * WorldDimensions.cellSize;
      const nextRoundedZ = Math.round(nextHead.position.z / WorldDimensions.cellSize) * WorldDimensions.cellSize;

      const prevRoundedX = Math.round(prevHead.position.x / WorldDimensions.cellSize) * WorldDimensions.cellSize;
      const prevRoundedZ = Math.round(prevHead.position.z / WorldDimensions.cellSize) * WorldDimensions.cellSize;

      // find the diff of current position and nearest cell
      const nextDiffX = Math.abs(nextRoundedX - this.head.position.x);
      const nextDiffZ = Math.abs(nextRoundedZ - this.head.position.z);

      const prevDiffX = Math.abs(nextRoundedX - this.head.position.x);
      const prevDiffZ = Math.abs(nextRoundedZ - this.head.position.z);

      // const currentDiffX = nextDiffX <= prevDiffX ? nextDiffX : prevDiffX;
      // const currentDiffZ = nextDiffZ <= prevDiffZ ? nextDiffZ : prevDiffZ;

      // if we are changing direction, check if diff is smaller than moveDistance then round position to nearest cell.
      if (this.direction !== this.newDirection /*&& (currentDiffX < WorldDimensions.moveDistance || currentDiffZ < WorldDimensions.moveDistance)*/) {
        this.direction = this.newDirection;

        this.head.position.x = nextDiffX <= prevDiffX ? nextRoundedX : prevRoundedX;
        this.head.position.z = nextDiffZ <= prevDiffZ ? nextRoundedZ : prevRoundedZ;
        RotateShape(this.direction, this.head);
        this.newDirection = CubeDirection.forward;
      } else {
        moveShapeForward(this.head);
      }

      if (this.isJumping) {
        this.UpdateHeight();
      }

      for (let i = this.body.length - 1; i > 0; i--) {
          this.body[i].position.x = this.body[i - 1].position.x;
          this.body[i].position.z = this.body[i - 1].position.z;
          this.body[i].position.y = this.body[i - 1].position.y;
          this.body[i].quaternion.slerp(this.body[i - 1].quaternion, 1);      
      }
      this.body[0].position.x = this.head.position.x;
      this.body[0].position.z = this.head.position.z;
      this.body[0].position.y = this.head.position.y;
      this.body[0].quaternion.slerp(this.head.quaternion, 1); 
    }
  }

  UpdateDirection(newDirection: CubeDirection) {
      this.newDirection = newDirection;
  }

  UpdateLength(scene: Scene) {
    for (let i = 0; i < 4; i++) {
      let bodyCopy;
      if (this.body.length < 1) {
        bodyCopy = this.head.clone();
      } else {
        bodyCopy = this.body[this.body.length-1].clone();
      }
      bodyCopy.translateZ((i * 0.25));
      // const newBody = createCapsule(undefined, undefined ,undefined, SnakeColour, bodyCopy.position);
      const newBody = createCube(undefined, SnakeColour, bodyCopy.position);
      this.body.push(newBody);
      if (this.body.length > 1) {
        scene.add(newBody);
      }
    }
  }
}

export default Snake;