/**
 * @class SessionContext
 * @description Context provider for a session
 */
import * as React from "react"


interface UserProfile {
  username: string,
  highscore?: number
}

export interface SessionContextValues {
  activeSession?: boolean;
  userProfile?: UserProfile
}

const SessionContext: React.Context<SessionContextValues> = React.createContext<SessionContextValues>({ activeSession: false, userProfile: undefined });

export const useSession = () => React.useContext(SessionContext);

export default SessionContext;
 
 