import "./PageRoutes.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// import Account from "./pages/Account/Account";
import Game from "./pages/Game/Game";
import { Home } from "./pages/Home/Home";
// import Leaderboard from "./pages/Leaderboard/Leaderboard";
// import LoginRegister from "./pages/LoginRegister/LoginRegister";

export const PageRoutes = () => {
  const location = useLocation();
  return (
    <>
      <TransitionGroup>
        <CSSTransition key={location.pathname} timeout={500} classNames="fade">
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              {/* <Route path="login" element={<LoginRegister />} />
              <Route path="register" element={<LoginRegister />} />
              <Route path="loginregister" element={<LoginRegister />} />
            <Route path="account" element={<Account />} /> */}
              <Route path="game" element={<Game />} />
              {/* <Route path="leaderboard" element={<Leaderboard />} /> */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};
