import * as React from "react";
// import {
//   createLeaderboardEntry,
//   LeaderboardEntry,
// } from "../../../api/apiUtils";
import { useSession } from "../../../hooks/useSession";
import GameModal, { GameModalProps } from "./GameModal";
// import { useMutation } from "react-query";
// import { AxiosResponse } from "axios";

interface GameOverModalProps extends GameModalProps {
  score: number;
}

const GameOverModal: React.FC<GameOverModalProps> = ({
  switchCallback,
  checked,
  buttonCallback,
  score,
}) => {
  const { userProfile } = useSession();

  const highscore = userProfile?.highscore || 0;

  const isNewHighscore = score > highscore;
  // const newHighscore = React.useCallback(() => {
  //   return score > highscore;
  // }, [highscore, score]);

  // const { mutate } = useMutation<AxiosResponse, unknown, LeaderboardEntry>(
  //   createLeaderboardEntry
  // );

  // React.useEffect(() => {
  //   if (
  //     session.activeSession &&
  //     session.userProfile?.username &&
  //     isNewHighscore
  //   ) {
  //     mutate({ username: session.userProfile.username, highscore: score });
  //   }
  // }, [
  //   mutate,
  //   isNewHighscore,
  //   score,
  //   session.activeSession,
  //   session.userProfile?.username,
  // ]);

  React.useEffect(() => {
    if (isNewHighscore) {
      localStorage.setItem("WormholeGameHighscore", score.toString());
    }
  });

  return (
    <GameModal
      switchCallback={switchCallback}
      checked={checked}
      buttonCallback={buttonCallback}
      buttonText="Play again"
      topStyle="25%"
    >
      <h1>Game Over</h1>
      {isNewHighscore ? (
        <>
          <h2>New highscore!</h2>
          <div>Highscore: {score}</div>
        </>
      ) : (
        <>
          <div>Score: {score}</div>
          <div>Highscore: {highscore}</div>
        </>
      )}
    </GameModal>
  );
};

export default GameOverModal;
