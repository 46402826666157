import Snake from "../Artifacts/Snake";
import { CubeDirection } from "./ShapeUtils";

export const handleKeyInput = (event: KeyboardEvent, snake: Snake) => {
  switch (event.key) {
    case ("a"):
    case ("ArrowLeft"):
    case ("Left"):
      {
        snake.UpdateDirection(CubeDirection.left);
        break;
      }
    case ("d"):
    case ("ArrowRight"):
    case ("Right"):
      {
        snake.UpdateDirection(CubeDirection.right);
        break;
      }
    case (" "): 
      {
        if (!snake.isJumping) {
          snake.isJumping = true;
        }
      }
  }
};

export interface TouchInput {
  x: number,
  y: number
}

export const handleTouchInput = (touchStart: TouchInput, touchEnd: TouchInput, snake: Snake) => {
  const diffX = Math.abs(touchStart.x - touchEnd.x);
  const diffY = Math.abs(touchStart.y - touchEnd.y);

  if (diffX > diffY) {
    if (touchStart.x < touchEnd.x) {
      snake.UpdateDirection(CubeDirection.right);
    } else if (touchStart.x > touchEnd.x) {
      snake.UpdateDirection(CubeDirection.left);
    }
  } else if (touchStart.y > touchEnd.y) {
    if (!snake.isJumping) {
      snake.isJumping = true;
    }
  }
}