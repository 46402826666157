import { Box3, BufferGeometry, Line, LineBasicMaterial, Mesh, MeshStandardMaterial, Object3D, PerspectiveCamera, Scene, SphereGeometry, Texture, Vector3 } from "three";
import { createAsteroids } from "../Artifacts/Asteroid";
import Snake from "../Artifacts/Snake";
import { createSun, randomiseSunPosition, Sun } from "../Artifacts/Sun";
import { createWormholes, Wormhole } from "../Artifacts/Wormhole";
import { WorldDimensions } from "../Game";
import { initialiseCamera, UpdateCamera } from "./CameraUtils";
import { initialiseDirectionalLighting, initialiseAmbientLighting } from "./LightingUtil";
import BoundaryBox from "../Artifacts/Boundary";

export const collisionDetection = (snakeHeadBoundingBox: Box3, otherBoundingBox: Box3) => {
  const collision = (
    snakeHeadBoundingBox.max.x < otherBoundingBox.min.x 
    || snakeHeadBoundingBox.min.x > otherBoundingBox.max.x
    || snakeHeadBoundingBox.max.z < otherBoundingBox.min.z
    || snakeHeadBoundingBox.min.z > otherBoundingBox.max.z
    || snakeHeadBoundingBox.max.y < otherBoundingBox.min.y
    || snakeHeadBoundingBox.min.y > otherBoundingBox.max.y
  ) ? false : true;

  return collision;
}

export const addGridLines = (scene: Scene, thinLineMaterial: LineBasicMaterial) => {
  let gridExists = false;
  scene.traverse((element: Object3D) => {
    if (element.type === "Line"){
      gridExists = true;
    }
  })
  if (!gridExists){
    for (let i = -1; i < WorldDimensions.width; i+=WorldDimensions.cellSize){
      const pointA = new Vector3(i, -WorldDimensions.cellSize, -1);
      const pointB = new Vector3(i, -WorldDimensions.cellSize, WorldDimensions.depth + 1);
      const pointX = new Vector3(-1, -WorldDimensions.cellSize, i);
      const pointY = new Vector3(WorldDimensions.width + 1, -WorldDimensions.cellSize, i);
  
      const geometryAB = new BufferGeometry().setFromPoints([
        pointA,
        pointB
      ]);
  
      const geometryXY = new BufferGeometry().setFromPoints([
        pointX,
        pointY
      ]);
  
      const lineAB = new Line(geometryAB, thinLineMaterial);
      const lineXY = new Line(geometryXY, thinLineMaterial);
      scene.add(lineAB);
      scene.add(lineXY);
    }
  }
}

export const removeGridLines = (scene: Scene) => {
  const toRemove: Object3D[] = [];
  scene.traverse((element: Object3D) => {
    if(element.name === "gridLine"){
      toRemove.push(element);
    } else if (element.type === "Line"){
      toRemove.push(element);
    }
  })

  for (let element of toRemove){
    scene.remove(element)
  }
}

export const removeArtifactsFromScene = (
  scene: Scene, 
  camera: PerspectiveCamera,
  snake: Snake,
  invisibleCameraTarget: Object3D,
  sun: Sun,
  asteroids: Mesh<SphereGeometry, MeshStandardMaterial>[],
  wormholes: Wormhole[],
) => {
  scene.remove(camera);
  scene.remove(snake.head);
  
  for (let i = 0; i < snake.body.length; i++) {
    scene.remove(snake.body[i]);
  }

  scene.remove(invisibleCameraTarget);
  scene.remove(sun.mesh);
  scene.remove(sun.pointLight);

  for(let i = 0; i < asteroids.length; i++) {
    scene.remove(asteroids[i]);
  }

  for (const wormhole of wormholes) {
    scene.remove(wormhole.a);
    scene.remove(wormhole.haloA);
    scene.remove(wormhole.b);
    scene.remove(wormhole.haloB);
  }
}

export const createGameArtifactsAndSetScene = (
  scene: Scene,
  snakeTexture?: Texture
): {
  camera: PerspectiveCamera;
  snake: Snake;
  invisibleCameraTarget: Object3D;
  sun: Sun;
  asteroids: Mesh<SphereGeometry, MeshStandardMaterial>[];
  asteroidsBoundingBoxes: Box3[];
  wormholes: Wormhole[];
} => {
  const camera = initialiseCamera();
  const invisibleCameraTarget = new Object3D();
  invisibleCameraTarget.position.set(0, 0, -15);
  camera.lookAt(invisibleCameraTarget.position);
  invisibleCameraTarget.add(camera);
  scene.add(invisibleCameraTarget);

  const snake = new Snake(snakeTexture);
  scene.add(snake.head);

  UpdateCamera(invisibleCameraTarget, snake.head);

  const asteroids: Mesh<SphereGeometry, MeshStandardMaterial>[] =
    createAsteroids(15);
  for (const asteroid of asteroids) {
    scene.add(asteroid);
  }

  const asteroidsBoundingBoxes: Box3[] = [];
  for (let i = 0; i < asteroids.length; i++) {
    const obstacleBoundingBox = new Box3().setFromObject(asteroids[i]);
    obstacleBoundingBox.expandByScalar(-0.5);
    asteroidsBoundingBoxes.push(obstacleBoundingBox);
  }

  // create wormholes
  const wormholes = createWormholes(asteroids, camera);
  for (const wormhole of wormholes) {
    scene.add(wormhole.a);
    scene.add(wormhole.haloA);
    scene.add(wormhole.b);
    scene.add(wormhole.haloB);
    const wormholeABoundingBox = new Box3().setFromObject(wormhole.a);
    wormholeABoundingBox.expandByScalar(-1);
    wormhole.boundingBoxA = wormholeABoundingBox;
    const wormholeBBoundingBox = new Box3().setFromObject(wormhole.b);
    wormholeBBoundingBox.expandByScalar(-1);
    wormhole.boundingBoxB = wormholeBBoundingBox;
  }

  const sun = createSun(scene);
  randomiseSunPosition(sun, asteroidsBoundingBoxes, wormholes);

  return {
    camera,
    snake,
    invisibleCameraTarget,
    sun,
    asteroids,
    asteroidsBoundingBoxes,
    wormholes,
  };
};

export const initialiseScene = (
  scene: Scene
): {
  snake: Snake;
  camera: PerspectiveCamera;
  invisibleCameraTarget: Object3D;
  sun: Sun;
  asteroids: Mesh<SphereGeometry, MeshStandardMaterial>[];
  asteroidsBoundingBoxes: Box3[];
  wormholes: Wormhole[];
} => {

  // create out of bounds lines
  const boundary = BoundaryBox();
  scene.add(boundary);
  

  // add lighting to scene
  const directionalLighting = initialiseDirectionalLighting();
  const ambientLighting = initialiseAmbientLighting();
  scene.add(directionalLighting);
  scene.add(ambientLighting);

  let {
    snake,
    camera,
    invisibleCameraTarget,
    sun,
    asteroids,
    asteroidsBoundingBoxes,
    wormholes,
  } = createGameArtifactsAndSetScene(scene);

  return {
    snake,
    camera,
    invisibleCameraTarget,
    sun,
    asteroids,
    asteroidsBoundingBoxes,
    wormholes,
  };
};