import * as React from "react";
import { useThree } from "@react-three/fiber";
import { Vector3, sRGBEncoding } from "three";
import AsteroidShower from "./AsteroidShower";
import Sun from "./Sun";
import Worm from "./Worm";
import Wormhole from "./Wormhole";
import { OrbitControls } from "@react-three/drei";
import { SkyboxContextValues } from "../../../hooks/useSkybox";
import Loader from "./Loader";

interface Props {
  skyboxContext: SkyboxContextValues;
}

export const Scene = ({ skyboxContext }: Props) => {
  const { scene } = useThree();

  const [wormholeAPosition] = React.useState(new Vector3(-5, 0, 0));
  const [wormholeBPosition] = React.useState(new Vector3(5, 0, 0));
  const [showScene, setShowScene] = React.useState(false);

  React.useEffect(() => {
    if (skyboxContext.skybox) {
      skyboxContext.skybox.encoding = sRGBEncoding;
      scene.background = skyboxContext.skybox;
      setShowScene(true);
    }
  }, [scene, skyboxContext.skybox]);

  return (
    <>
      {showScene && scene.background ? (
        <>
          <OrbitControls />
          <ambientLight />
          <pointLight position={[0, 5, 0]} />
          <Wormhole position={wormholeAPosition} size={1} />
          <Wormhole position={wormholeBPosition} size={1} />
          <Worm />
          <Sun position={new Vector3(0, 0, 0)} />
          <AsteroidShower />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
