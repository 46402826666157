/**
 * Custom halo effect shader 
 */
export const haloVertexShader = `
  uniform vec3 viewVector;
  varying vec3 vNormal;
  varying vec3 vEye;
  void main(){
    vNormal = normalize(normalMatrix * normal);
    vEye = normalize(normalMatrix * viewVector);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

export const haloFragmentShader = `
  varying vec3 vNormal;
  varying vec3 vEye;
  uniform float c;
  uniform float p;
  uniform vec3 glowColor;
  void main(){
    float a = (c - dot(vNormal, vEye));
    a = pow(a, p);
    gl_FragColor = vec4(glowColor * a, a);
  }
`;