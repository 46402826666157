import * as React from "react";
import GameModal, { GameModalProps } from "./GameModal";

const GameStartModal: React.FC<GameModalProps> = ({
  switchCallback,
  checked,
  buttonCallback,
}) => {
  return (
    <GameModal
      switchCallback={switchCallback}
      checked={checked}
      buttonCallback={buttonCallback}
      buttonText="Start"
      topStyle="15%"
    >
      <h2>Aim:</h2>
      <p>
        Collect the suns to grow and get points. Try to reach the highest score!
      </p>
      <p>Avoid the asteroids and yourself! Or the game will end.</p>
      <p>Tip: Use the wormholes to your advantage.</p>
      <h2>Instructions:</h2>
      <p>
        Use the A,D or Left Arrow and Right Arrow keys to change direction. Or,
        swipe left and right on your touch screen.
      </p>
      <p>Use the space bar or swipe up on your touch screen to jump.</p>
    </GameModal>
  );
};

export default GameStartModal;
