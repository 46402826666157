import "./index.css";
import * as React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
// import HeaderBar from "./components/HeaderBar";
import SkyboxContextProvider from "./hooks/SkyboxContextProvider";
import { Loader } from "@react-three/drei";
import { QueryClient, QueryClientProvider } from "react-query";
import SessionContextProvider from "./hooks/SessionContextProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { PageRoutes } from "./PageRoutes";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const container = document.getElementById("root");

const root = createRoot(container!);

const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <SessionContextProvider>
          {/* <HeaderBar /> */}
          <React.Suspense fallback={<Loader />}>
            <SkyboxContextProvider>
              <PageRoutes />
            </SkyboxContextProvider>
          </React.Suspense>
        </SessionContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
