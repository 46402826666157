import { Vector3, BufferGeometry, BufferAttribute } from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { WorldDimensions } from "../Game";


const BoundaryBox = () => {
  // create out-of-bounds lines.
  const thickLineMaterial = new LineMaterial({
    color: 0xfdf4e2,
    linewidth: 0.0025,
  });

  const point1 = new Vector3(-1, -WorldDimensions.cellSize * 0.5, -1);
  const point2 = new Vector3(
    WorldDimensions.width + 1,
    -WorldDimensions.cellSize * 0.5,
    -1
  );
  const point3 = new Vector3(
    -1,
    -WorldDimensions.cellSize * 0.5,
    WorldDimensions.depth + 1
  );
  const point4 = new Vector3(
    WorldDimensions.width + 1,
    -WorldDimensions.cellSize * 0.5,
    WorldDimensions.depth + 1
  );

  const geometry = new BufferGeometry().setFromPoints([point1, point2, point1, point3, point3, point4, point2, point4]);
  const lineGeometry = new LineGeometry().setPositions(
    (geometry.attributes.position as BufferAttribute).array as Float32Array
  );
  const line = new Line2(lineGeometry, thickLineMaterial);

  return line;
}

export default BoundaryBox;
