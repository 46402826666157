import * as React from "react";
import { FormControlLabel, Switch, Button } from "@mui/material";
import { ChevronRight, KeyboardArrowDown } from "@mui/icons-material";

interface SettingsProps {
  switchCallback: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const Settings: React.FC<SettingsProps> = ({ switchCallback, checked }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      className="settings"
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "10%",
      }}
    >
      <span>
        <h5 style={{ display: "inline" }}>Extra settings:</h5>
        <Button onClick={() => setIsOpen(!isOpen)}>
          {!isOpen && <ChevronRight style={{ color: "aliceblue" }} />}
          {isOpen && <KeyboardArrowDown style={{ color: "aliceblue" }} />}
        </Button>
      </span>

      {isOpen && (
        <>
          <FormControlLabel
            control={
              <Switch
                onChange={switchCallback}
                color="secondary"
                checked={checked}
              />
            }
            label="Enable grid lines"
            style={{ justifyContent: "center" }}
          />
        </>
      )}
    </div>
  );
};

export default Settings;
