import * as React from "react";
import SkyboxContext from "./useSkybox";
import { useCubeTexture } from "@react-three/drei";

import skyboxFront from "../assets/backgound_skybox/front.jpg";
import skyboxBack from "../assets/backgound_skybox/back.jpg";
import skyboxBottom from "../assets/backgound_skybox/bottom.jpg";
import skyboxTop from "../assets/backgound_skybox/top.jpg";
import skyboxLeft from "../assets/backgound_skybox/left.jpg";
import skyboxRight from "../assets/backgound_skybox/right.jpg";

interface SkyboxContextProviderProps {
  children: JSX.Element | JSX.Element[];
}

const SkyboxContextProvider: React.FC<SkyboxContextProviderProps> = ({
  children,
}) => {
  const [skyboxContextValue] = React.useState({
    skybox: useCubeTexture(
      [
        skyboxRight,
        skyboxLeft,
        skyboxTop,
        skyboxBottom,
        skyboxFront,
        skyboxBack,
      ],
      { path: "" }
    ),
  });

  return (
    <SkyboxContext.Provider value={skyboxContextValue}>
      {children}
    </SkyboxContext.Provider>
  );
};

export default SkyboxContextProvider;
