import * as React from "react";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import Asteroid from "./Asteroid";

const AsteroidShower: React.FC = () => {
  const [asteroids, setAsteroids] = React.useState<JSX.Element[]>([]);

  const [colourMap, normalMap, occlusionMap, displacementMap] = useLoader(
    TextureLoader,
    [
      "AsteroidTextures/Asteroid_Colour.jpg",
      "AsteroidTextures/Asteroid_Normal.jpg",
      "AsteroidTextures/Asteroid_Occlusion.jpg",
      "AsteroidTextures/Asteroid_Displacement.png",
    ]
  );

  React.useEffect(() => {
    const newAsteroids = [];
    for (let i = 0; i < 20; i++) {
      newAsteroids.push(
        <Asteroid
          key={i}
          colourMap={colourMap}
          normalMap={normalMap}
          occlusionMap={occlusionMap}
          displacementMap={displacementMap}
        />
      );
    }
    setAsteroids(newAsteroids);
  }, [colourMap, displacementMap, normalMap, occlusionMap]);

  return <>{asteroids}</>;
};

export default AsteroidShower;
