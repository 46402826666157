import { DirectionalLight, AmbientLight } from "three";


export const initialiseDirectionalLighting = (): DirectionalLight => {
  const color = 0xFFFFFF;
  const intensity = 0.5;
  const light = new DirectionalLight(color, intensity);
  light.position.set(0, 5, 0);
  return light;
};

export const initialiseAmbientLighting = (): AmbientLight => {
  const light = new AmbientLight( 0x404040, 1.0 ); // soft white light
  return light;
};