/**
 * @class SkyboxContext
 * @description Context provider for the skybox
 */
 import * as React from "react"
 import { CubeTexture } from "three";

 export interface SkyboxContextValues {
   skybox: CubeTexture | undefined;
 }

const SkyboxContext: React.Context<SkyboxContextValues> = React.createContext<SkyboxContextValues>({ skybox: undefined });

export const useSkybox = () => React.useContext(SkyboxContext);

export default SkyboxContext;
  