import * as React from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Color, BackSide, AdditiveBlending, Vector3 } from "three";
import {
  haloVertexShader,
  haloFragmentShader,
} from "../../../assets/shaders/Halo";
import {
  sunVertexShader,
  sunFragmentShader,
} from "../../../assets/shaders/Sun";

interface SunProps {
  position: Vector3;
}

//0xffff00 original colour
const Sun: React.FC<SunProps> = ({ position }) => {
  const { camera } = useThree();

  const sunRef = React.useRef<THREE.Mesh>(null!);
  const haloRef = React.useRef<THREE.Mesh>(null!);
  const sunShaderRef = React.useRef<THREE.ShaderMaterial>(null!);
  const haloShaderRef = React.useRef<THREE.ShaderMaterial>(null!);
  const [start] = React.useState(Date.now());

  const sunShaderData = {
    uniforms: {
      time: {
        type: "f",
        value: 0.0,
      },
    },
    vertexShader: sunVertexShader,
    fragmentShader: sunFragmentShader,
    ref: sunShaderRef,
  };

  const haloShaderData = {
    uniforms: {
      c: { value: 0.5 },
      p: { value: 1.5 },
      glowColor: { value: new Color(0xffd100) },
      viewVector: {
        value: camera.position.clone().sub(position),
      },
    },
    vertexShader: haloVertexShader,
    fragmentShader: haloFragmentShader,
    side: BackSide,
    blending: AdditiveBlending,
    transparent: true,
    ref: haloShaderRef,
  };

  useFrame(() => {
    sunShaderRef.current.uniformsNeedUpdate = true;
    sunShaderRef.current.uniforms.time.value = 0.00025 * (Date.now() - start);

    haloShaderRef.current.uniformsNeedUpdate = true;
    haloShaderRef.current.uniforms.viewVector.value = haloRef.current
      .clone()
      .position.sub(camera.position)
      .negate();
  });

  return (
    <>
      <mesh position={position} ref={sunRef}>
        <sphereGeometry args={[0.5, 64, 64]} />
        <shaderMaterial {...sunShaderData} ref={sunShaderRef} />
        <pointLight
          position={position}
          color={0xffff00}
          intensity={2}
          distance={8}
        />
      </mesh>
      <mesh position={position} scale={1.15} ref={haloRef}>
        <sphereGeometry args={[0.5, 64, 64]} />
        <shaderMaterial {...haloShaderData} ref={haloShaderRef} />
      </mesh>
    </>
  );
};

export default Sun;
