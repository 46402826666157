import "./Game.css";
import * as React from "react";
import {
  Box3,
  Clock,
  ColorManagement,
  CubeTexture,
  LineBasicMaterial,
  PerspectiveCamera,
  Scene,
  sRGBEncoding,
  Vector3,
  WebGLRenderer,
} from "three";
import { UpdateCamera } from "./Utility/CameraUtils";
import { wormholeCollisionDetection } from "./Artifacts/Wormhole";
import { randomiseSunPosition, sunCollisionDetection } from "./Artifacts/Sun";
import {
  addGridLines,
  collisionDetection,
  createGameArtifactsAndSetScene,
  initialiseScene,
  removeArtifactsFromScene,
  removeGridLines,
} from "./Utility/GameUtils";
import { CircularProgress } from "@mui/material";
import { useSkybox } from "../../hooks/useSkybox";
import {
  handleKeyInput,
  handleTouchInput,
  TouchInput,
} from "./Utility/InputUtils";
import GameOverModal from "./Components/GameOverModal";
import GameStartModal from "./Components/GameStartModal";
import { Link } from "react-router-dom";

const initialMoveDistance = 0.5;
export const WorldDimensions = {
  width: 100,
  depth: 100,
  cellSize: 2,
  moveDistance: initialMoveDistance,
};
ColorManagement.enabled = true;

const resizeCanvasToDisplaySize = (
  renderer: WebGLRenderer,
  camera: PerspectiveCamera
) => {
  const canvas = renderer.domElement;
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;

  if (canvas.width !== width || canvas.height !== height) {
    renderer.setSize(width, height, false);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
  }
};

const Game = (): JSX.Element => {
  const skyboxContext = useSkybox();
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [isLoading] = React.useState(false);
  const [overallScore, setScore] = React.useState(0);
  const [isGameOver, setIsGameOver] = React.useState(false);
  const newGame = React.useRef(false);
  const [isGamePaused, setIsGamePaused] = React.useState(true);
  const gamePaused = React.useRef(true);
  const [isGridEnabled, setIsGridEnabled] = React.useState(false);
  const gridEnabled = React.useRef(false);

  const skybox = skyboxContext.skybox as CubeTexture;
  skybox.encoding = sRGBEncoding;

  // initialisation on mount
  React.useEffect(() => {
    // initialise canvas references to point to three js renderer
    const renderer = new WebGLRenderer({ antialias: true });
    renderer.outputEncoding = sRGBEncoding;
    renderer.setPixelRatio(window.devicePixelRatio);

    if (canvasRef && canvasRef.current) {
      canvasRef.current.replaceWith(renderer.domElement);
    }

    const scene = new Scene();
    scene.background = skyboxContext.skybox as CubeTexture;

    let {
      snake,
      camera,
      invisibleCameraTarget,
      sun,
      asteroids,
      asteroidsBoundingBoxes,
      wormholes,
    } = initialiseScene(scene);

    //limit fps to 60
    const clock = new Clock();
    const interval = 1 / 60;
    let delta = 0;

    let score = 0;
    let gameOver = false;

    // continously renders the scene
    const animate = () => {
      delta += clock.getDelta();
      resizeCanvasToDisplaySize(renderer, camera);

      // resetGame, remove current artifacts and replace with new ones.
      if (newGame.current) {
        removeArtifactsFromScene(
          scene,
          camera,
          snake,
          invisibleCameraTarget,
          sun,
          asteroids,
          wormholes
        );

        ({
          snake,
          camera,
          invisibleCameraTarget,
          sun,
          asteroids,
          asteroidsBoundingBoxes,
          wormholes,
        } = createGameArtifactsAndSetScene(scene));

        if (gridEnabled.current) {
          const thinLineMaterial = new LineBasicMaterial({ color: 0x3a3b3c });
          addGridLines(scene, thinLineMaterial);
        } else {
          removeGridLines(scene);
        }
        newGame.current = false;
        gamePaused.current = false;
        gameOver = false;
        score = 0;
      }

      if (!gamePaused.current) {
        const snakeHeadBoundingBox = new Box3().setFromObject(snake.head);
        const sunBoundingBox = new Box3().setFromObject(sun.mesh);

        // sun collision detection
        const sunCollision = sunCollisionDetection(
          snakeHeadBoundingBox,
          sunBoundingBox
        );
        if (sunCollision) {
          score++;
          snake.UpdateLength(scene);
          WorldDimensions.moveDistance += 0.025;
          randomiseSunPosition(sun, asteroidsBoundingBoxes, wormholes);
        }

        // wormhole collision detection
        for (const wormholePair of wormholes) {
          const { collisionWormholeA, collisionWormholeB } =
            wormholeCollisionDetection(wormholePair, snakeHeadBoundingBox);
          if (collisionWormholeA) {
            snake.head.position.x = wormholePair.b.position.x;
            snake.head.position.y = wormholePair.b.position.y;
            snake.head.position.z = wormholePair.b.position.z;
            snake.head.translateZ(-(WorldDimensions.cellSize * 0.8));
          } else if (collisionWormholeB) {
            snake.head.position.x = wormholePair.a.position.x;
            snake.head.position.y = wormholePair.a.position.y;
            snake.head.position.z = wormholePair.a.position.z;
            snake.head.translateZ(-(WorldDimensions.cellSize * 0.8));
          }
        }

        // snake collision detection
        for (let i = 24; i < snake.body.length; i++) {
          const bodyPartBoundingBox = new Box3().setFromObject(snake.body[i]);
          const collision = collisionDetection(
            snakeHeadBoundingBox,
            bodyPartBoundingBox
          );
          if (collision) {
            snake.pause = true;
            gameOver = true;
            setScore(score);
            setIsGameOver(true);
            break;
          }
        }

        // obstacle collision detection
        for (let i = 0; i < asteroidsBoundingBoxes.length; i++) {
          const collision = collisionDetection(
            snakeHeadBoundingBox,
            asteroidsBoundingBoxes[i]
          );
          if (collision) {
            snake.pause = true;
            gameOver = true;
            setScore(score);
            setIsGameOver(true);
            break;
          }
        }

        // move snake and camera
        if (!gameOver || !snake.pause) {
          if (delta > interval) {
            snake.Update();
            UpdateCamera(invisibleCameraTarget, snake.head);
            delta = delta % interval;
          }
          const cameraWorldPosition = camera.getWorldPosition(new Vector3());

          // update halo effect
          for (const wormhole of wormholes) {
            wormhole.haloA.material.uniformsNeedUpdate = true;
            wormhole.haloA.material.uniforms.viewVector.value = wormhole.haloA
              .clone()
              .position.sub(cameraWorldPosition)
              .negate();
            wormhole.haloB.material.uniformsNeedUpdate = true;
            wormhole.haloB.material.uniforms.viewVector.value = wormhole.haloB
              .clone()
              .position.sub(cameraWorldPosition)
              .negate();
          }
        }
      }
      renderer.render(scene, camera);
      // this will call our render() function before the browser performs the next repaint
      requestAnimationFrame(animate);
    };
    // first render sets off the loop
    animate();

    // handle input, not sure why I can't extract this into a util file.
    window.addEventListener(
      "keydown",
      (event) => handleKeyInput(event, snake),
      false
    );

    const touchStart: TouchInput = {
      x: 0,
      y: 0,
    };

    window.addEventListener(
      "touchstart",
      (event) => {
        touchStart.x = Math.trunc(event.changedTouches[0].screenX);
        touchStart.y = Math.trunc(event.changedTouches[0].screenY);
      },
      false
    );

    window.addEventListener(
      "touchend",
      (event) => {
        const touchEnd: TouchInput = {
          x: Math.trunc(event.changedTouches[0].screenX),
          y: Math.trunc(event.changedTouches[0].screenY),
        };
        handleTouchInput(touchStart, touchEnd, snake);
      },
      false
    );

    window.addEventListener(
      "touchmove",
      (event) => {
        event.preventDefault();
      },
      false
    );
  }, [skyboxContext.skybox]);

  const resetGame = () => {
    newGame.current = true;
    setIsGameOver(false);
    setScore(0);
    WorldDimensions.moveDistance = initialMoveDistance;
  };

  const startGame = () => {
    newGame.current = true;
    setIsGamePaused(false);
  };

  const handleGridEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    gridEnabled.current = event.target.checked;
    setIsGridEnabled(event.target.checked);
  };

  return (
    <div className="Game">
      <div className="GameArea">
        <Link to="/">
          <h3 className="logo">Wormhole</h3>
        </Link>
        {isLoading && (
          <div id="loading">
            <CircularProgress disableShrink color="secondary" />
          </div>
        )}
        <canvas
          ref={canvasRef}
          style={{ height: "100vh", width: "100vw", display: "block" }}
          className="gameCanvas"
        />
        {!isLoading && isGameOver && (
          <GameOverModal
            switchCallback={handleGridEnabled}
            checked={isGridEnabled}
            buttonCallback={resetGame}
            score={overallScore}
          />
        )}
        {!isLoading && isGamePaused && (
          <GameStartModal
            switchCallback={handleGridEnabled}
            checked={isGridEnabled}
            buttonCallback={startGame}
          />
        )}
      </div>
    </div>
  );
};

export default Game;
