import { Object3D, Mesh, MeshStandardMaterial, PerspectiveCamera } from "three";

export const initialiseCamera = (): PerspectiveCamera  => {
  const fov = 50;
  const aspect = 1920 / 1080 
  const near = 0.1;
  const far = 2000;
  const camera = new PerspectiveCamera(fov, aspect, near, far);
  camera.position.y = 50;
  camera.position.z = 35;
  return camera;
};


export const UpdateCamera = (invisibleCameraTarget: Object3D, head: Mesh<any, MeshStandardMaterial>) => {

  invisibleCameraTarget.position.x = head.position.x;
  invisibleCameraTarget.position.y = head.position.y;
  invisibleCameraTarget.position.z = head.position.z;

  if (invisibleCameraTarget.quaternion !== head.quaternion) {
    invisibleCameraTarget.quaternion.slerp(head.quaternion, 0.3)
  }
}


// camera controls
// const cameraFolder = gui.addFolder('Camera')
// cameraFolder.add(camera.rotation, 'x', 0, Math.PI * 2)
// cameraFolder.add(camera.rotation, 'y', 0, Math.PI * 2)
// cameraFolder.add(camera.rotation, 'z', 0, Math.PI * 2)
// cameraFolder.add(camera.position, 'x', -100, 100)
// cameraFolder.add(camera.position, 'y', -100, 100)
// cameraFolder.add(camera.position, 'z', -100, 100)