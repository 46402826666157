import * as React from "react";
import { useFrame } from "@react-three/fiber";

const Worm: React.FC = () => {
  const wormLength = 32;
  const bodyRefs = React.useRef<THREE.Mesh[]>(Array(wormLength));

  useFrame((state, delta) => {
    for (let i = wormLength - 1; i > 0; i--) {
      bodyRefs.current[i].rotation.x = bodyRefs.current[i - 1].rotation.x;
      bodyRefs.current[i].rotation.y = bodyRefs.current[i - 1].rotation.y;
      bodyRefs.current[i].rotation.z = bodyRefs.current[i - 1].rotation.z;
      bodyRefs.current[i].position.x = bodyRefs.current[i - 1].position.x;
      bodyRefs.current[i].position.y = bodyRefs.current[i - 1].position.y;
      bodyRefs.current[i].position.z = bodyRefs.current[i - 1].position.z;
    }

    bodyRefs.current[0].rotation.x += 0.5 * delta;
    bodyRefs.current[0].rotation.y += 1 * delta;

    bodyRefs.current[0].position.x = 0 + Math.sin(state.clock.elapsedTime);
    bodyRefs.current[0].position.y = 0 + Math.sin(state.clock.elapsedTime * 3);
  });

  const wormMesh = () => {
    const meshes = [];
    for (let i = 0; i < wormLength; i++) {
      meshes.push(
        <mesh
          position={[0, 0, 0]}
          ref={(el) => (el ? (bodyRefs.current[i] = el) : null)}
          key={i}
        >
          <mesh position={[0, 0, 3]}>
            <boxGeometry args={[0.25, 0.25, 0.25]} />
            <meshStandardMaterial color={"blueviolet"} />
          </mesh>
        </mesh>
      );
    }
    return meshes;
  };

  return <>{wormMesh()}</>;
};

export default Worm;
