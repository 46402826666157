import * as React from "react";
import { useFrame } from "@react-three/fiber";
import {
  Color,
  Euler,
  MeshStandardMaterial,
  SphereGeometry,
  Texture,
  Vector3,
} from "three";

interface AsteroidProps {
  colourMap: Texture;
  normalMap: Texture;
  occlusionMap: Texture;
  displacementMap: Texture;
}

const Asteroid: React.FC<AsteroidProps> = ({
  colourMap,
  normalMap,
  occlusionMap,
  displacementMap,
}) => {
  const asteroidRef = React.useRef<
    THREE.Mesh<SphereGeometry, MeshStandardMaterial>
  >(null!);

  const [position, setPosition] = React.useState(new Vector3(0, 0, 0));
  const [rotation, setRotation] = React.useState(new Euler(0, 0, 0));
  const [size, setSize] = React.useState(Math.random() * 0.5 + 0.1);
  const [noise, setNoise] = React.useState(Math.random());

  const shouldBeRecreated = (position: Vector3) => {
    if (position.x < -50) {
      setNoise(Math.random());
    }
  };

  useFrame((_, delta) => {
    asteroidRef.current.position.x -= 4 * noise * -(size - 4) * delta;
    asteroidRef.current.rotation.y += noise * delta;
    shouldBeRecreated(asteroidRef.current.position);
  });

  // change in noise will randomise its size and positioning and speed (reusing same mesh).
  React.useEffect(() => {
    const x = 50;
    const y = Math.random() * 10 * (Math.random() < 0.5 ? -1 : 1);
    const z = Math.random() * 15 + 5;
    setPosition(new Vector3(x, y, z));
    setRotation(new Euler(x, y, z));
    setSize(Math.random() * 0.5 + 0.1);
  }, [noise]);

  const grey = new Color(0x696969);

  return (
    <>
      <mesh position={position} ref={asteroidRef} rotation={rotation}>
        <sphereGeometry args={[size, 64, 64]} />
        <meshStandardMaterial
          map={colourMap}
          color={grey}
          normalMap={normalMap}
          aoMap={occlusionMap}
          displacementMap={displacementMap}
          displacementScale={0.05}
        />
      </mesh>
    </>
  );
};

export default Asteroid;
