import * as React from "react";
import "./Home.css";
import { Canvas } from "@react-three/fiber";
import { Scene } from "./Components/Scene";
import { useSkybox } from "../../hooks/useSkybox";
import { Link } from "react-router-dom";

export const Home = (): JSX.Element => {
  const skyboxContext = useSkybox();
  const [classes, setClasses] = React.useState("");

  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  React.useEffect(() => {
    if (skyboxContext.skybox) {
      setClasses("fadeIn");
    }
  }, [skyboxContext.skybox]);

  return (
    <div className="home">
      <h1 className="homeHeader">Welcome to Wormhole</h1>
      <Canvas
        camera={{ position: [0, 0, -10], fov: 60 }}
        className={classes}
        style={{ zIndex: 0 }}
      >
        <Scene skyboxContext={skyboxContext} />
      </Canvas>
      <Link to="game">
        <h2 className="startButton">Start</h2>
      </Link>
    </div>
  );
};
