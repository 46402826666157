import { Mesh, Box3, PointLight, Scene, MeshBasicMaterial, SphereGeometry } from "three";
import { WorldDimensions } from "../Game";
import { collisionDetection } from "../Utility/GameUtils";
import { createBasicSphere, randomisePosition } from "../Utility/ShapeUtils";
import { Wormhole, wormholeCollisionDetection } from "./Wormhole";

export interface Sun {
  mesh: Mesh<SphereGeometry, MeshBasicMaterial>,
  pointLight: PointLight
}


export const createSun = (scene: Scene): Sun => {
  const sun = createBasicSphere(undefined, undefined, "yellow");
  const pointLight = new PointLight(0xf1ebc8, 2, 25);
  pointLight.castShadow = true;
  pointLight.position.set(sun.position.x, sun.position.y, sun.position.z);
  sun.add(pointLight);
  scene.add(sun);
  scene.add(pointLight);
  return {
    mesh: sun,
    pointLight
  };
}

export const randomiseSunPosition = (sun: Sun, obstaclesBoundingBoxes: Box3[], wormholes: Wormhole[]) => {
  let notObstructed = false;
  while (!notObstructed) {
    randomisePosition(sun.mesh.position);
    const newsunBoundingBox = new Box3().setFromObject(sun.mesh);
    let collisionFound = false;
    obstaclesBoundingBoxes.forEach((boundingBox) => {
      if (collisionDetection(newsunBoundingBox, boundingBox)) {
        collisionFound = true;
      }
    })

    wormholes.forEach((wormholePair) => {
      const { collisionWormholeA, collisionWormholeB } = wormholeCollisionDetection(wormholePair, newsunBoundingBox);
      if (collisionWormholeA || collisionWormholeB) {
        collisionFound = true;
      }
    })

    if (collisionFound === false) {
      notObstructed = true;
      sun.pointLight.position.set(sun.mesh.position.x, sun.mesh.position.y, sun.mesh.position.z);
    }
  }
}

export const sunCollisionDetection = (snakeHeadBoundingBox: Box3, sunBoundingBox: Box3) => {
  const collisionsun = (
    snakeHeadBoundingBox.max.x - (WorldDimensions.cellSize / 2) < sunBoundingBox.min.x 
    || snakeHeadBoundingBox.min.x + (WorldDimensions.cellSize / 2) > sunBoundingBox.max.x
    || snakeHeadBoundingBox.max.z - (WorldDimensions.cellSize / 2) < sunBoundingBox.min.z
    || snakeHeadBoundingBox.min.z + (WorldDimensions.cellSize / 2) > sunBoundingBox.max.z
    || snakeHeadBoundingBox.max.y - (WorldDimensions.cellSize / 2) < sunBoundingBox.min.y
    || snakeHeadBoundingBox.min.y + (WorldDimensions.cellSize / 2) > sunBoundingBox.max.y
  ) ? false : true;

  return collisionsun;
}