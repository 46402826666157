import * as React from "react";
// import { AxiosResponse } from "axios";
// import { useMutation, useQuery } from "react-query";
// import {
//   LeaderboardEntry,
//   getLeaderboardEntry,
//   postVerify,
//   VerifyParams,
// } from "../api/apiUtils";
import SessionContext from "./useSession";
import { useSession } from "./useSession";

interface SessionContextProviderProps {
  children: JSX.Element | JSX.Element[];
}

const SessionContextProvider: React.FC<SessionContextProviderProps> = ({
  children,
}) => {
  const context = useSession();
  const [username, setUsername] = React.useState("");
  const [highscore, setHighscore] = React.useState(0);
  // const [validSession, setValidSession] = React.useState(false);

  const usernameInStorage = localStorage.getItem("WormholeGameUsername");
  const highscoreInStorage = localStorage.getItem("WormholeGameHighscore");
  // check local storage for username
  React.useEffect(() => {
    if (usernameInStorage) {
      setUsername(usernameInStorage as string);
    }

    if (highscoreInStorage && Number(highscoreInStorage)) {
      setHighscore(Number(highscoreInStorage));
    }
  }, [usernameInStorage, highscoreInStorage]);

  // const {
  //   data: sessionResponse,
  //   isLoading,
  //   mutate,
  // } = useMutation<AxiosResponse, unknown, VerifyParams>(postVerify);

  // if username exists, lets verify the sessionId
  // React.useEffect(() => {
  //   if (username.length > 0) {
  //     mutate({ username });
  //   }
  // }, [mutate, username]);

  // React.useEffect(() => {
  //   if (sessionResponse?.status === 200) {
  //     setValidSession(true);
  //     context.activeSession = true;
  //   } else {
  //     context.activeSession = false;
  //     setValidSession(false);
  //   }
  // }, [context, isLoading, sessionResponse?.status]);

  // if its a valid session lets get the highscore
  // const { data } = useQuery<unknown, unknown, LeaderboardEntry>(
  //   ["entry", username],
  //   () => getLeaderboardEntry(username),
  //   {
  //     enabled: validSession,
  //   }
  // );

  // we now set the session context with userprofile
  React.useEffect(() => {
    context.userProfile = {
      username: username,
      highscore: highscore,
    };
  }, [context, highscore, username]);

  return (
    <SessionContext.Provider value={context}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
