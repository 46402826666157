import {
  Mesh,
  BoxGeometry,
  ColorRepresentation,
  Vector3,
  MeshStandardMaterial,
  Texture,
  SphereGeometry,
  MeshBasicMaterial,
  CapsuleGeometry,
  CylinderGeometry,
  Color,
} from "three";
import { WorldDimensions } from "../Game";

export enum CubeDirection {
  right = "right",
  left = "left",
  forward = "forward",
  backward = "backward",
}

export const createCube = (
  texture?: Texture,
  colour?: ColorRepresentation,
  position?: Vector3
): Mesh<BoxGeometry, MeshStandardMaterial> => {
  const boxWidth = WorldDimensions.cellSize;
  const boxHeight = WorldDimensions.cellSize;
  const boxDepth = WorldDimensions.cellSize;
  const geometry = new BoxGeometry(boxWidth, boxHeight, boxDepth);

  const materialConfig = colour ? { color: colour } : { map: texture };
  const material = new MeshStandardMaterial(materialConfig);
  const cube = new Mesh(geometry, material);

  if (position) {
    cube.position.x = position.x;
    cube.position.z = position.z;
  } else {
    cube.position.x = WorldDimensions.width * 0.5;
    cube.position.z = WorldDimensions.depth * 0.5;
  }
  return cube;
};

export const createBasicSphere = (
  radius?: number,
  texture?: Texture,
  colour?: ColorRepresentation,
  position?: Vector3
): Mesh<SphereGeometry, MeshBasicMaterial> => {
  const geometry = new SphereGeometry(
    radius ? radius : WorldDimensions.cellSize * 0.5
  );

  const materialConfig = colour ? { color: colour } : { map: texture };
  const material = new MeshBasicMaterial(materialConfig);
  const sphere = new Mesh(geometry, material);

  if (position) {
    sphere.position.x = position.x;
    sphere.position.z = position.z;
  } else {
    sphere.position.x = WorldDimensions.width * 0.5;
    sphere.position.z = WorldDimensions.depth * 0.5;
  }
  return sphere;
};

interface TextureMaps {
  colourMap: Texture;
  normalMap: Texture;
  occlusionMap: Texture;
  displacementMap: Texture;
}

export const createSphere = (
  radius?: number,
  textureMaps?: TextureMaps,
  colour?: ColorRepresentation,
  position?: Vector3,
  rotation?: number,
): Mesh<SphereGeometry, MeshStandardMaterial> => {
  const geometry = new SphereGeometry(
    radius ? radius : WorldDimensions.cellSize * 0.5
  );

  const materialConfig = colour
    ? { color: colour }
    : {
        map: textureMaps?.colourMap,
        normalMap: textureMaps?.normalMap,
        aoMap: textureMaps?.occlusionMap,
        displacementMap: textureMaps?.displacementMap,
        displacementScale: 0.15,
        color: new Color(0x696969)
      };
  const material = new MeshStandardMaterial(materialConfig);
  const sphere = new Mesh(geometry, material);

  if (position) {
    sphere.position.x = position.x;
    sphere.position.z = position.z;
  } else {
    sphere.position.x = WorldDimensions.width * 0.5;
    sphere.position.z = WorldDimensions.depth * 0.5;
  }

  if (rotation) {
    sphere.rotation.x = rotation;
    sphere.rotation.y = rotation;
    sphere.rotation.z = rotation;
  }
  return sphere;
};

export const createCapsule = (
  radius?: number,
  length?: number,
  texture?: Texture,
  colour?: ColorRepresentation,
  position?: Vector3
): Mesh<CapsuleGeometry, MeshStandardMaterial> => {
  const geometry = new CapsuleGeometry(
    radius || WorldDimensions.cellSize * 0.5,
    length || WorldDimensions.cellSize * 0.25,
    2,
    20
  );
  geometry.rotateX((90 * Math.PI) / 180);

  const materialConfig = colour ? { color: colour } : { map: texture };
  const material = new MeshStandardMaterial(materialConfig);
  const capsule = new Mesh(geometry, material);

  if (position) {
    capsule.position.x = position.x;
    capsule.position.z = position.z;
  } else {
    capsule.position.x = WorldDimensions.width * 0.5;
    capsule.position.z = WorldDimensions.depth * 0.5;
  }
  return capsule;
};

export const createCylinder = (
  radius?: number,
  length?: number,
  texture?: Texture,
  colour?: ColorRepresentation,
  position?: Vector3
): Mesh<CylinderGeometry, MeshStandardMaterial> => {
  const geometry = new CylinderGeometry(
    radius || WorldDimensions.cellSize * 0.5,
    radius || WorldDimensions.cellSize * 0.5,
    length || WorldDimensions.cellSize,
    20,
    1
  );
  geometry.rotateX((90 * Math.PI) / 180);

  const materialConfig = colour ? { color: colour } : { map: texture };
  const material = new MeshStandardMaterial(materialConfig);
  const cylinder = new Mesh(geometry, material);

  if (position) {
    cylinder.position.x = position.x;
    cylinder.position.z = position.z;
  } else {
    cylinder.position.x = WorldDimensions.width * 0.5;
    cylinder.position.z = WorldDimensions.depth * 0.5;
  }
  return cylinder;
};

export const moveShapeForward = (shape: Mesh<any, any>) => {
  shape.translateZ(-WorldDimensions.moveDistance);

  const width = WorldDimensions.width;
  const depth = WorldDimensions.depth;

  if (shape.position.x < -0.01) {
    shape.position.x = width;
  }
  if (shape.position.x > width) {
    shape.position.x = 0;
  }
  if (shape.position.z < -0.01) {
    shape.position.z = depth;
  }
  if (shape.position.z > depth) {
    shape.position.z = 0;
  }
};

export const RotateShape = (
  direction: CubeDirection,
  shape: Mesh<any, any>
) => {
  if (direction === CubeDirection.left) {
    shape.rotateY((90 * Math.PI) / 180);
  }
  if (direction === CubeDirection.right) {
    shape.rotateY((-90 * Math.PI) / 180);
  }
};

export const randomisePosition = (position: Vector3) => {
  // get random position in board that is a multiple of cube size.
  position.x =
    Math.floor(
      (Math.random() * (WorldDimensions.width - WorldDimensions.cellSize)) /
        WorldDimensions.cellSize
    ) * WorldDimensions.cellSize;
  position.z =
    Math.floor(
      (Math.random() * (WorldDimensions.depth - WorldDimensions.cellSize)) /
        WorldDimensions.cellSize
    ) * WorldDimensions.cellSize;
};
