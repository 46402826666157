import { Mesh, Vector3, MeshStandardMaterial, AdditiveBlending, BackSide, Box3, BufferGeometry, Camera, Color, ShaderMaterial, SphereGeometry, ColorRepresentation } from "three";
import { haloFragmentShader, haloVertexShader } from "../../../assets/shaders/Halo";
import { WorldDimensions } from "../Game";
import { randomisePosition } from "../Utility/ShapeUtils";


export interface Wormhole {
  a: Mesh<SphereGeometry, MeshStandardMaterial>
  haloA: Mesh<BufferGeometry, ShaderMaterial>
  boundingBoxA?: Box3
  b: Mesh<SphereGeometry, MeshStandardMaterial>
  haloB: Mesh<BufferGeometry, ShaderMaterial>
  boundingBoxB?: Box3
}

const createWormhole = (colour: ColorRepresentation) => {
  const radius = WorldDimensions.cellSize * 0.7;
  const geometry = new SphereGeometry(radius);

  const materialConfig = {color: colour};
  const material = new MeshStandardMaterial( materialConfig ); 
  const wormhole = new Mesh(geometry, material);
  wormhole.geometry.attributes.position.needsUpdate = true
  wormhole.matrixAutoUpdate = true;
  return wormhole;
}

export const createWormholes = (obstacles: Mesh<SphereGeometry, MeshStandardMaterial>[], camera: Camera): Wormhole[] => {
  const wormholes: Wormhole[] = [];
  while(wormholes.length < 1){
      const wormholeALocation = new Vector3(0,0,0);
      const wormholeBLocation = new Vector3(0,0,0);
      randomisePosition(wormholeALocation);
      randomisePosition(wormholeBLocation);
      if(Math.abs(wormholeALocation.x - wormholeBLocation.x) > 35 || Math.abs(wormholeALocation.z - wormholeBLocation.z) > 35){
        let notObstructed = true;
        obstacles.forEach(obstacle => {
          if( 
            obstacle.position.x === wormholeALocation.x 
            && obstacle.position.z === wormholeALocation.z 
            &&  obstacle.position.x === wormholeBLocation.x 
            && obstacle.position.z === wormholeBLocation.z 
          ){
            notObstructed = false;
          }
        })

        if (notObstructed) {
          const wormholeA = createWormhole("black");
          wormholeA.position.set(wormholeALocation.x, wormholeALocation.y, wormholeALocation.z);
    
          const wormholeB = createWormhole("black");
          wormholeB.position.set(wormholeBLocation.x, wormholeBLocation.y, wormholeBLocation.z);
          
          const customMaterial = new ShaderMaterial( 
            {
              uniforms: 
              { 
                "c":   { value: 0.5 },
                "p":   { value: 5.0 },
                glowColor: { value: new Color(0xfdf4e2) }, //0077ff
                viewVector: { value: camera.position}
              },
              vertexShader: haloVertexShader, 
              fragmentShader: haloFragmentShader, 
              side: BackSide,
              blending: AdditiveBlending,
              transparent: true,
            }
          );
          
          const haloA = new Mesh( wormholeA.geometry.clone(), customMaterial.clone() );
          haloA.position.x = wormholeA.position.x;
          haloA.position.z = wormholeA.position.z;
          haloA.position.y = wormholeA.position.y;
          haloA.scale.multiplyScalar(1.25);
          
          const haloB = new Mesh( wormholeB.geometry.clone(), customMaterial.clone() );
          haloB.position.x = wormholeB.position.x;
          haloB.position.z = wormholeB.position.z;
          haloB.position.y = wormholeB.position.y;
          haloB.scale.multiplyScalar(1.25);

          const pair = {
            a: wormholeA,
            haloA: haloA,
            b: wormholeB,
            haloB: haloB,
          }
          wormholes.push(pair);
        }
      }
      
  }
  return wormholes;
}

export const wormholeCollisionDetection = (wormholePair: Wormhole, snakeHeadBoundingBox: Box3) => {
  const wormholeAboundingBox =  wormholePair.boundingBoxA as Box3;
  const wormholeBboundingBox =  wormholePair.boundingBoxB as Box3;

  const collisionWormholeA = (
    snakeHeadBoundingBox.max.x < wormholeAboundingBox.min.x 
    || snakeHeadBoundingBox.min.x > wormholeAboundingBox.max.x
    || snakeHeadBoundingBox.max.z < wormholeAboundingBox.min.z
    || snakeHeadBoundingBox.min.z > wormholeAboundingBox.max.z
    || snakeHeadBoundingBox.max.y < wormholeAboundingBox.min.y
    || snakeHeadBoundingBox.min.y > wormholeAboundingBox.max.y
  ) ? false : true;

  const collisionWormholeB = (
    snakeHeadBoundingBox.max.x < wormholeBboundingBox.min.x 
    || snakeHeadBoundingBox.min.x > wormholeBboundingBox.max.x
    || snakeHeadBoundingBox.max.z < wormholeBboundingBox.min.z
    || snakeHeadBoundingBox.min.z > wormholeBboundingBox.max.z
    || snakeHeadBoundingBox.max.y < wormholeBboundingBox.min.y
    || snakeHeadBoundingBox.min.y > wormholeBboundingBox.max.y
  ) ? false : true;

  return {
    collisionWormholeA,
    collisionWormholeB
  }
}

